
let baseurl = "https://api.l-sou.com"
// let baseurl = "https://192.168.2.12:9001"

let home = 'https://www.l-sou.com'
let CODE = {
    SUCCESS: 0,
    FAILED: 400,
    TOKENINVALID: 4104,
    UNKNOWN: 500
}

export default {
    baseurl, CODE, home,
    queryURLParams(url) {
        let parames = {}; // 定义参数对象
        let tmp = url.split('?')
        if (tmp.length === 1) {
            return parames
        }
        let tmp2 = tmp[1].split('&')
        tmp2.forEach(item => {
            let pattern = /(\w+)=(.+)/ig; //定义正则表达式
            item.replace(pattern, ($, $1, $2) => {
                parames[$1] = $2;
            });
        })
        return parames;

    },

 

    
    

};
