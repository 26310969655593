import axios from 'axios';
import router from '../router';
import global from '../plugins/global';
import {MessageBox} from 'element-ui';
import {Message} from 'element-ui'
import qs from 'qs'

let neterror = 0;
let tokeninvalidconfirm = false;
let requestlist = [];
let config = {
    baseURL: global.baseurl,
    timeout: 10000,
    withCredentials: false,
    headers: {'Content-Type': 'application/json'},
    /**
     * 这个字段为自定义字段，用来表示请求限制类型
     * 无限制(none)
     * 防抖(debounce)：单位时间内多次相同的请求，第一次请求立即执行，后续请求无效
     */
    astrict: 'debounce',
};
const _axios = axios.create(config);
_axios.interceptors.request.use((config) => {
    //当收到一个请求时，将请求构建成异步任务
    return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem('lsou_token');
        if (token) {
            config.headers['Authorization'] = token;
        }
        if (config.astrict === 'debounce') {
            let requestdetail = getrequestdetail(config);
            let reqindex = requestlist.findIndex((c) => {
                return c.detail === requestdetail;
            });
            if (reqindex > -1) {
                let reqobj = requestlist[reqindex];
                //移除历史计时器
                clearTimeout(reqobj.timeout);
                //重新设置新的计时器
                reqobj.timeout = setTimeout(() => {
                    let rd = getrequestdetail(config);
                    requestlist.splice(requestlist.indexOf(rd), 1);
                }, 800);
                requestlist[reqindex] = reqobj;
                //移除本次请求
                new axios.CancelToken((c) => {
                    c();
                    reject("requestagain");
                });
            } else {
                //新的请求，设置超时后移除历史记录，添加并执行
                let obj = {};
                obj.timeout = setTimeout(() => {
                    let rd = getrequestdetail(config);
                    requestlist.splice(requestlist.indexOf(rd), 1);
                }, 800);
                obj.detail = requestdetail;
                requestlist.push(obj);
                resolve(config);
            }
        } else if (config.astrict === 'none') {
            resolve(config);
        }
    });
});

_axios.interceptors.response.use(
    function (response) {
        console.log(response.headers)
        if (response.headers['content-type'] === 'application/octet-stream') {
            const blob = new Blob([response.data]);
            let fileName = '导出数据.csv'
            let downloadElement = document.createElement('a')
            let href = window.URL.createObjectURL(blob) //创建下载的链接
            downloadElement.href = href
            downloadElement.download = fileName
            document.body.appendChild(downloadElement)
            downloadElement.click()
            document.body.removeChild(downloadElement)
            window.URL.revokeObjectURL(href)
        }
        if (response.data.code === global.CODE.SUCCESS) {
            if (response.data.msg) {
                Message.success(response.data.msg);
            }
            return response.data;
        }
        if (response.data.code === global.CODE.FAILED) {
            if (response.data.msg) {
                Message.error(response.data.msg)
            }
            return Promise.reject('FAIL');
        }
        if (response.data.code === global.CODE.TOKENINVALID) {
            if (!tokeninvalidconfirm) {
                tokeninvalidconfirm = true;
                MessageBox.confirm('请重新登录, 是否返回到登录页面?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    closeOnClickModal: false,
                    type: 'warning'
                }).then(() => {

                }).finally(() => {
                    tokeninvalidconfirm = false;
                });
            }
            return Promise.reject('TOKENINVALID');
        }
        if (response.data.code === global.CODE.UNKNOWN) {
            Message.error('未知的异常');
            return Promise.reject('UNKNOWN');
        }
    },
    function (error) {
        if ("requestagain" === error) {
            return Promise.reject('重复提交，请求已经被过滤');
        }
        if (neterror === 0) {
            neterror++;
            Message({
                message: '服务响应异常，请检查网络或联系管理员',
                type: "error",
                center: true,
                onClose: function () {
                    neterror = 0;
                }
            })
        }
        return Promise.reject('服务响应异常');
    }
);

//构造请求详情
function getrequestdetail(config) {
    let detail = config.method + ':' + config.url;
    if (config.data) {
        detail += '?' + qs.stringify(config.data);
    }
    if (config.params) {
        detail += '?' + qs.stringify(config.params);
    }
    return detail;
}

export default _axios;
