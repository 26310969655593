import Vue from 'vue'
import VueRouter from 'vue-router'
import global from '@/plugins/global.js'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
    }, {
        path: '/test',
        name: 'Test',
        component: () => import(/* webpackChunkName: "Login" */ '../views/test.vue')
    },
    {
        path: '/personalCenter',
        name: 'personalCenter',
        // redirect: to => {
        //     //处理token并重定向
        //     let geturl = window.location.href
        //     if (geturl.indexOf('token=') && geturl.indexOf('page=')) {
        //         let t1 = geturl.split('?token=')[1];
        //         let t = t1.split('&')[0];
        //         let p = geturl.split('&page=')[1];
        //         if (t) {
        //             sessionStorage.setItem('lsou_token', t);
        //             return {path: p, query: null}
        //         }
        //     }
        //     return {path: home, query: null}
        // },
        component: () => import(/* webpackChunkName: "personalCenter" */ '../views/personalCenter.vue'),
        children: [
            {
                path: '/personalCenter/accountSetting',
                name: 'accountSetting',
                component: () =>
                    import(/* webpackChunkName: "accountSetting" */ '../views/accountSetting.vue'),
                meta: {
                    title: '账户设置'
                },
            },
            {
                path: '/personalCenter/integralInformation',
                name: 'integralInformation',
                component: () =>
                    import(/* webpackChunkName: "integralInformation" */ '../views/integralInformation.vue'),
                meta: {
                    title: '积分信息'
                },
            },
            {
                path: '/personalCenter/messageRecord',
                name: 'messageRecord',
                component: () =>
                    import(/* webpackChunkName: "messageRecord" */ '../views/messageRecord.vue'),
                meta: {
                    title: '消息记录'
                },
            },
            {
                path: '/personalCenter/packagePlanning',
                name: 'packagePlanning',
                component: () =>
                    import(/* webpackChunkName: "packagePlanning" */ '../views/packagePlanning.vue'),
                meta: {
                    title: '套餐计划'
                },
            },
            {
                path: '/personalCenter/pluginSetting',
                name: 'pluginSetting',
                component: () =>
                    import(/* webpackChunkName: "pluginSetting" */ '../views/pluginSetting.vue'),
                meta: {
                    title: '插件设置'
                },
            },
            {
                path: '/personalCenter/invitedUsers',
                name: 'invitedUsers',
                component: () =>
                    import('../views/invitedUsers.vue'),
                meta: {
                    title: '城市合伙人'
                },
            },

        ]
    },
    {
        path: '/favourCenter',
        name: 'favourCenter',
        // redirect: to => {
        //     //处理token并重定向
        //     let geturl = window.location.href
        //     if (geturl.indexOf('token=') && geturl.indexOf('page=')) {
        //         let t1 = geturl.split('?token=')[1];
        //         let t = t1.split('&')[0];
        //         let p = geturl.split('&page=')[1];
        //         if (t) {
        //             sessionStorage.setItem('lsou_token', t);
        //             return {path: p, query: null}
        //         }
        //     }
        //     return {path: home, query: null}
        // },
        component: () => import(/* webpackChunkName: "favourCenter" */ '../views/favourCenter.vue'),
        children: [
            {
                path: '/favourCenter/productFavour',
                name: 'productFavour',
                component: () =>
                    import(/* webpackChunkName: "productFavour" */ '../views/productFavour.vue'),
                meta: {
                    title: '商品收藏'
                },
            },
            {
                path: '/favourCenter/keywordFavour',
                name: 'keywordFavour',
                component: () =>
                    import(/* webpackChunkName: "keywordFavour" */ '../views/keywordFavour.vue'),
                meta: {
                    title: '关键字收藏'
                },
            }
        ]
    },
    {
        path: '/subscriptionCenter',
        name: 'subscriptionCenter',
        // redirect: to => {
        //     //处理token并重定向
        //     let geturl = window.location.href
        //     if (geturl.indexOf('token=') && geturl.indexOf('page=')) {
        //         let t1 = geturl.split('?token=')[1];
        //         let t = t1.split('&')[0];
        //         let p = geturl.split('&page=')[1];
        //         if (t) {
        //             sessionStorage.setItem('lsou_token', t);
        //             return {path: p, query: null}
        //         }
        //     }
        //     return {path: home, query: null}
        // },
        component: () => import(/* webpackChunkName: "subscriptionCenter" */ '../views/subscriptionCenter.vue'),
        children: [
            {
                path: '/subscriptionCenter/aliRfqSubscription',
                name: 'aliRfqSubscription',
                component: () =>
                    import(/* webpackChunkName: "aliRfqSubscription" */ '../views/aliRfqSubscription.vue'),
                meta: {
                    title: 'RFQ发布监控'
                },
            },
            {
                path: '/subscriptionCenter/aliExpressMonitor',
                name: 'aliExpressMonitor',
                component: () =>
                    import(/* webpackChunkName: "aliRfqSubscription" */ '../views/aliExpressMonitor.vue'),
                meta: {
                    title: 'Aliexpress商品监控'
                },
            },
            {
                path: '/subscriptionCenter/aliShopeeMonitor',
                name: 'aliShopeeMonitor',
                component: () =>
                    import(/* webpackChunkName: "aliRfqSubscription" */ '../views/aliShopeeMonitor.vue'),
                meta: {
                    title: 'Shopee商品监控'
                },
            },
            {
                path: '/subscriptionCenter/BenchmarkStoreRecommendation',
                name: 'BenchmarkStoreRecommendation',
                component: () =>
                    import(/* webpackChunkName: "pluginSetting" */ '../views/BenchmarkStoreRecommendation.vue'),
                meta: {
                    title: '标杆店铺推荐'
                },
            },
        ]
    },
    {
        path: '/buyVip',
        name: 'buyVip',
        // redirect: to => {
        //     //处理token并重定向
        //     let geturl = window.location.href
        //     if (geturl.indexOf('token=') && geturl.indexOf('page=')) {
        //         let t1 = geturl.split('?token=')[1];
        //         let t = t1.split('&')[0];
        //         let p = geturl.split('&page=')[1];
        //         if (t) {
        //             sessionStorage.setItem('lsou_token', t);
        //             return {path: p, query: null}
        //         }
        //     }
        //     return {path: home, query: null}
        // },
        component: () => import(/* webpackChunkName: "subscriptionCenter" */ '../views/buyVip.vue'),
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
})

// router.beforeEach((to, from, next) => {
// if (to.path === '/login') {
//     next();
// } else {
//     if (sessionStorage.getItem('lsou_token')) {
//         next();
//     } else {
//         next({path: '/login'});
//     }
// }
// });

router.beforeEach((to, from, next) => {
    document.title = '来搜 ' + (to.meta.title || '')
    if (to.path === '/login') {
        next();
    } else if (to.path === '/test') {
        if (process.env.NODE_ENV === "development") {
            next()
        } else {
            next({ path: '/login' })
        }
    } else {
        let token = sessionStorage.getItem('lsou_token')
        console.info('token === ', token)
        if (!token) {
            // TODO 正式上线务必删除
            if (process.env.NODE_ENV === "development") {
                // token = 'eyJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3OTk5NDg1OTksInVpZCI6MzQsInJvbGUiOjAsInRva2VuIjoiNTAwOGM2NDcxODk0NGJiNGFiMzgzZGI3ZWRlMTEzMDUifQ.ufubpfYPTSK8nvYfK9x9Yc_iJMR4jDKiQXVgP5FrwvM'
                // token = 'eyJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2Nzk5MDQ4MzksInVpZCI6MTQ2LCJyb2xlIjowLCJpc3ZpcCI6ZmFsc2UsInRva2VuIjoiNjI4MGRkMmI3ZjIyNDYzYThkMTMyZmYwMjk2MDRjYmEifQ.Y-35cPUAq8p4fKYkOGW3ebJWuVr-zpYJX9bRwwcTfeY'
                sessionStorage.setItem('lsou_token', token)
                if (to.path === '/') {
                    next({ path: '/test' })
                } else {
                    next()
                }
                return
            }
            console.info('queryURLParams === ', global.queryURLParams)
            console.info('window.location.href === ', window.location.href)

            let params = global.queryURLParams(window.location.href)
            console.info('parans --- ', params)
            if (params.token && params.page) {
                sessionStorage.setItem('lsou_token', params.token);
                next({ path: params.page, query: null })
            } else {
                next({ path: '/login' })
            }
        } else {
            if (process.env.NODE_ENV === "development" && to.path === '/') {
                next({ path: '/test' })
            } else {
                next()
            }
        }


    }

})
export default router
