import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './plugins/global.scss';
import axios from './plugins/axios';
import global from './plugins/global';
import './assets/theme/index.css';//主题样式
import 'remixicon/fonts/remixicon.css'
import Qs from 'qs';
import mixin from '@/plugins/mixin'
Vue.config.productionTip = false;
Vue.prototype.axios = axios;
Vue.prototype.global = global;
Vue.prototype.Qs = Qs;
Vue.mixin(mixin)
Vue.use(ElementUI);

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
