import saveAs from "file-saver";
import Excel from "exceljs";

export default {
    methods: {
        setStyle(header, columns, dataList, expertName) {
            const EXCEL_TYPE =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let workbook = new Excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();
            let worksheet = workbook.addWorksheet("sheet1");
            worksheet.properties.defaultColWidth = 15;
            worksheet.columns = columns;
            //表头设置
            worksheet.getRow(1).values = header;
            worksheet.addRows(dataList);
        
            //默认样式
            worksheet.eachRow({
                includeEmpty: true
            }, (row, rowNumber) => {
                if (rowNumber >= 1) {
                    worksheet.getRow(rowNumber).height = 18;
                }
                worksheet
                    .getRow(rowNumber)
                    .eachCell({
                        includeEmpty: true
                    }, (cell, colNumber) => {
                        // 文字居中
                        worksheet.getRow(rowNumber).getCell(colNumber).alignment = {
                            vertical: "middle",
                            horizontal: "center",
                        };
                        //文字样式
                        worksheet.getRow(rowNumber).getCell(colNumber).font = {
                            size: 10,
                            name: "Microsoft YaHei",
                        };
                        //边框样式
                        worksheet.getRow(rowNumber).getCell(colNumber).border = {
                            top: {
                                style: "thin"
                            },
                            left: {
                                style: "thin"
                            },
                            bottom: {
                                style: "thin"
                            },
                            right: {
                                style: "thin"
                            },
                        };
                    });
            });
            //表头样式
            worksheet
                .getRow(1)
                .eachCell({
                    includeEmpty: true
                }, (cell, colNumber) => {
                    worksheet.getRow(1).getCell(colNumber).font = {
                        size: 11,
                        name: "Microsoft YaHei",
                        bold: true,
                    };
                });
        
            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
                saveAs(blob, `${expertName}.xlsx`);
            });
        }

        
    },

}